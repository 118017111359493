<template>
  <div>
    <b-overlay :show="loading">
      <b-card v-if="paketAktif.length > 0">
        <h4>Paket Anda</h4>
        <b-row>
          <b-col
            v-for="(item, i) in paketAktif"
            :key="i"
            sm="12"
            md="6"
            lg="4"
            class="mb-2"
          >
            <b-card class="border">
              <b-media vertical-align="top" class="mb-2">
                <template #aside>
                  <b-img
                    :src="apiFile + item.thumbnail"
                    blank-color="#ccc"
                    width="100"
                    alt="placeholder"
                  />
                </template>
                <small>7 Subtes</small>
                <h5 class="media-heading mt-1">
                  <b>{{ item.name }}</b>
                </h5>
              </b-media>
              <small
                class="font-weight-bold"
                v-if="item.info_end && item.is_active == 1"
              >
                Aktif hingga {{ humanDate(item.info_end) }}
              </small>
              <small
                class="font-weight-bold text-danger"
                v-else-if="item.info_end && item.is_active == 0"
              >
                <i>Membership Paket Sudah Kadaluwarsa</i>
              </small>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-overlay :show="loadingPaket">
      <b-card v-for="(item, i) in data" :key="i" no-body>
        <b-card-body>
          <div class="row" v-b-toggle="'collapse-' + i">
            <div class="col-sm-2">
              <b-img
                :src="
                  item.thumbnail != null
                    ? apiFile + item.thumbnail
                    : require('@/assets/images/elp/no-image.jpg')
                "
                blank-color="#ccc"
                width="100"
                alt="placeholder"
                class="mb-1 img-fluid"
              />
            </div>
            <div class="col-sm-6">
              <h4 class="media-heading mb-2">
                <b>{{ item.name }}</b>
              </h4>
              <b-badge variant="primary" class="mr-50">
                <feather-icon icon="UserIcon" />
              </b-badge>
              <small class="font-weight-bold">
                {{ item.sum_quota }} Berlangganan
              </small>
            </div>
            <div class="col-sm">
              <h4 class="media-heading text-right">
                <b>Rp{{ formatRupiah(item.price) }}</b>
              </h4>
              <small class="font-weight-bold float-right">
                1 Tahun masa aktif
              </small>
            </div>
            <div class="col-sm">
              <b-button
                class="ml-3 mt-2 float-right"
                variant="primary"
                @click="pilih(item)"
              >
                Pilih
              </b-button>
            </div>
          </div>
          <!-- <div class="d-flex justify-content-between align-items-center" v-b-toggle="'collapse-' + i">
            <div class="align-items-center text-body">
              <b-media vertical-align="top">
                <template #aside>
                  <b-img :src="item.thumbnail != null ? apiFile + item.thumbnail : require('@/assets/images/elp/no-image.jpg')" blank-color="#ccc" width="100" alt="placeholder" />
                </template>
                <h4 class="media-heading mb-2">
                  <b>{{ item.name }}</b>
                </h4>
                <b-badge variant="primary" class="mr-50">
                  <feather-icon icon="UserIcon" />
                </b-badge>
                <small class="font-weight-bold">{{ item.sum_quota }} Berlangganan</small>
              </b-media>
            </div>
            <div class="d-flex align-items-center text-body">
              <b-media vertical-align="top">
                <h4 class="media-heading">
                  <b> Rp{{ formatRupiah(item.price) }}</b>
                </h4>
                <small class="font-weight-bold">1 Tahun masa aktif</small>
              </b-media>
              <b-button class="ml-3" variant="primary" @click="pilih(item)"> Pilih </b-button>
            </div>
          </div> -->
          <b-collapse :id="`collapse-` + i" class="mt-4">
            <b-card-text class="card-text">
              {{ item.description ? item.description : "Tidak ada deskripsi" }}
            </b-card-text>
            <b-row class="mt-2">
              <b-col lg="6" cols="12">
                <b-card
                  border-variant="primary"
                  :header="'Subtest(' + item.sum_subtest_content + ')'"
                  header-bg-variant="primary"
                  header-text-variant="white"
                >
                  <b-card-body>
                    <app-collapse v-if="item.subtests[0] != null">
                      <div class="" v-for="(data, i) in item.subtests" :key="i">
                        <app-collapse-item
                          :title="data.title"
                          v-if="data.title && data.items != null"
                        >
                          <ul style="padding: 0">
                            <li
                              v-for="(items, i) in data.items"
                              :key="i"
                              class="mt-1"
                            >
                              <b>{{ items.title }}</b>
                              <br />
                              <feather-icon icon="ClockIcon" class="mr-50" />
                              <small class="font-weight-bold">
                                {{ items.timer }} Menit
                              </small>
                              <feather-icon
                                icon="FileTextIcon"
                                class="mr-50 ml-2"
                              />
                              <small class="font-weight-bold">
                                {{ items.sum_soal }} Soal
                              </small>
                            </li>
                          </ul>
                        </app-collapse-item>

                        <ul
                          style="padding: 0"
                          v-else-if="data.title && data.items == null"
                        >
                          <li>
                            <b>{{ data.title }}</b>
                            <br />
                            <feather-icon icon="ClockIcon" class="mr-50" />
                            <small class="font-weight-bold">
                              {{ data.timer }} Menit
                            </small>
                            <feather-icon
                              icon="FileTextIcon"
                              class="mr-50 ml-2"
                            />
                            <small class="font-weight-bold">
                              {{ data.sum_soal }} Soal
                            </small>
                          </li>
                        </ul>
                        <ul style="padding: 0" v-else>
                          <li>
                            <b>{{ data.nama_ujian }}</b>
                            <br />
                            <feather-icon icon="ClockIcon" class="mr-50" />
                            <small class="font-weight-bold">
                              {{ data.timer }} Menit
                            </small>
                            <feather-icon
                              icon="FileTextIcon"
                              class="mr-50 ml-2"
                            />
                            <small class="font-weight-bold">
                              {{ data.sum_soal }} Soal
                            </small>
                          </li>
                        </ul>
                      </div>
                    </app-collapse>
                    <div v-else>
                      <h5 class="text-danger text-center mt-2">
                        Tidak ada Subtest
                      </h5>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col lg="6" cols="12">
                <b-card
                  border-variant="primary"
                  header="Keuntungan"
                  header-bg-variant="primary"
                  header-text-variant="white"
                >
                  <b-card-body>
                    <div v-if="item.advantages[0] != null">
                      <p
                        class="mt-2"
                        v-for="(advantages, i) in item.advantages"
                        :key="i"
                      >
                        <feather-icon
                          icon="CheckCircleIcon"
                          size="24"
                          class="mr-25 text-success"
                        />
                        <span class="ml-25">{{ advantages.text }}</span>
                      </p>
                    </div>
                    <div v-else>
                      <h5 class="text-danger text-center mt-2">Tidak ada</h5>
                    </div>
                  </b-card-body>
                </b-card>
                <b-card
                  border-variant="primary"
                  header="Pertanyaan Umum"
                  header-bg-variant="primary"
                  header-text-variant="white"
                >
                  <b-card-body>
                    <app-collapse accordion v-if="item.faqs[0] != null">
                      <app-collapse-item
                        v-for="(faq, i) in item.faqs"
                        :key="i"
                        :title="faq.question"
                      >
                        <div v-html="faq.answer" />
                      </app-collapse-item>
                    </app-collapse>
                    <div v-else>
                      <h5 class="text-danger text-center mt-2">Tidak ada</h5>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-collapse>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BCollapse,
  BButton,
  VBToggle,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BImg,
  BMedia,
  BBadge,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BOverlay,
    BButton,
    BCard,
    BCardHeader,
    BCollapse,
    BCardBody,
    BCardTitle,
    BImg,
    BMedia,
    BBadge,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      data: [],
      paketAktif: [],
      loading: false,
      loadingPaket: false,
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  methods: {
    pilih(data) {
      this.$store.commit("paketTryout/SET_DETAIL", data);
      this.$router.push({ name: "payment", params: { id: data.slug } });
    },
    getData() {
      this.loadingPaket = true;
      let params = {
        orderCol: "price",
        order: "asc",
        show_subtest: 1,
        my_paket: 1,
      };
      this.$store
        .dispatch("paketTryout/index", params)
        .then((res) => {
          this.loadingPaket = false;
          let dataPaket = res.data.data;
          this.data = dataPaket;
        })
        .catch((err) => {
          this.loadingPaket = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    getDataTryoutAktif() {
      this.loading = true;
      let params = {};
      this.$store
        .dispatch("tryout/indexMyPaket", params)
        .then((res) => {
          this.loading = false;
          this.paketAktif = res.data.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },
  created() {
    this.getData();
    this.getDataTryoutAktif();
  },
};
</script>
<style>
li.mt-1 {
  border-bottom: 3px solid #e83b07;
  padding-bottom: 10px;
  list-style: none;
}
</style>
